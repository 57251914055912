import React, { Component } from "react";

import logo from './../icons/logo.jpg';
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { getClientData } from "../DataModelEngine";

class NavigationBar extends Component {
    constructor () {
        super();
        this.state = {};
    } 
    

  render (){
    const menuItems = [
        {title: "Hjem", link: ''},
        {title: "Bygg og rehabilitering", link: 'bygg-og-rehabilitering'},
        {title: "Fukt og Skadesanering", link: 'fukt-og-skadesanering'},
        {title: "Om Oss", link: 'om-oss'},        
        {title: "Kontakt oss", link: 'kontakt'}
    ];

    return (
        <Navbar 
            variant="light" 
            expand="lg" 
            id="navigation-menu" 
            style={{color: '#000'}} 
            collapseOnSelect
        >
            <Navbar.Brand 
                href="/">				
                    <div className='nav-logo'>
                        <img 
                            src={logo} 
                            alt={getClientData('clientCompanyName')} 
                        />
                    </div>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse >
                <Nav 
                    className="ml-auto" 
                    style={{zIndex: '999'}}>
                    
                    {menuItems.length > 0 && menuItems.map((item, index)=>(
                    <>
                        {Array.isArray(item) && <NavDropdown title={item[0]} id="navbarScrollingDropdown">
                            {item.map((submenu, index) => (
                                index !== 0 && <NavDropdown.Item href={submenu.link}>{submenu.title}</NavDropdown.Item>
                            ))}
                            {//<NavDropdown.Divider />
                            }
                        </NavDropdown>}
                        {!Array.isArray(item) && <Nav.Link 
                        name={item.link} 
                        key={index} 
                        href={'/'+item.link}>
                            {item.title}
                        </Nav.Link>} 
                    </>   
                    ))}

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )}
}

export default NavigationBar;