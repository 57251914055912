import knowhow from './../icons/knowhow.svg';
import about_pic from './../header_pictures/about_01.jpg';
import bygg_pic from './../header_pictures/bygg_01.jpg';
import fukt_pic from './../header_pictures/fukt_01.jpeg';


const articles = [
    {
        brief: 'Fukt og Skadesanering',
        full: [
            ['img', fukt_pic],
            ['header', 'Skadebegrensning etter vann- og flomskader'],
            ['paragraph', 'I Norge er det et økende problem med vannskader og fuktskader. Sopp- og fuktskader ødelegger mer i norske bygg enn brannskader. Årsaken har alltid sammenheng med fukt, for eksempel fukt som følge av lekkasje og kondens. Fukt fører til råte og muggsopp.'],
            ['paragraph', 'Du som huseier må være klar over at vannskade er noe som skjer plutselig, eksempel brudd på rør og at fuktskader er noe som skjer over tid. Sistnevnte dekkes sjelden av forsikring, og kan gi by på store økonomiske og helsemessige utfordringer.'],
            ['header', 'Det er derfor viktig at du utbedrer vann og fuktskader så fort som mulig.'],
            ['paragraph', 'LAUR Gruppen AS er en tømrer-entreprenør med spisskompetanse innen fukt- og tørketeknikk.Vi har spesialisert oss på å sanere og utbedre skader på dette området og har tilgang til metoder og utstyr som sikrer en korrekt behandling av vannskader av alle typer og omfang.Valg av riktige reparasjonsmetode er avgjørende for reparasjonens kvalitet, kostnad, risiko for inneklimaproblem og ikke minst reparasjonstiden.Det er derfor viktig at du utbedrer vann og fuktskader så fort som mulig.'],
            ['header', 'Hva kan vi hjelpe deg med og de viktigste oppgavene som må gjøres...'],
            ['paragraph', 'Vi er klare til å reagere på kort varsel og når vi blir tilkalt til et skadested, er vår første oppgave å vurdere skadeomfanget på skadestedet og hvorvidt det er nødvendig med akutte skadebegrensende tiltak.'],
            ['paragraph', 'Er det nødvendig med tiltak, kan vi raskt iverksette lokalisering av skadeårsak, stoppe ytterligere tilførsel av vann, fjerning av fritt vann, flytte eller tildekke inventar for å forhindre ytterligere skader. Etter utførte førstehjelpstiltak starter prosessene med fjerning av ødelagt løsøre og bygningsdeler. Avfukting / tørking av oppfuktede bygningsdeler, hvor vi benytter moderne tørkeutstyr som er energieffektivt. Vær snill å ikke skru av vifter eller flytt på dem uten at du er i kontakt med din prosjektleder. Om du gjør endringer vil tørkeprosessen kunne ta lengre tid enn nødvendig.'],
            ['paragraph', 'Tett og god kommunikasjon mellom deg og prosjektleder er viktig, spesielt i denne fasen av utbedringen av skaden,Vi garanterer at vi skal bistå deg med det som er mest optimalt med din utfordring enten det er vann eller fuktskade.'],
            ['header', 'Skadebegrensning etter vann- og flomskader omfatter blant annet:'],
            ['list', ['Behandling av forsikringskrav',
                'Fjerning av fritt vann',
                'Flytte innbo',
                'Sikring av innbo',
                'Skadevurdering',
                'Avfukting / Tørking',
                'Riving',
                'Mugg- og soppsanering',
                'Håndverkstjenester',
                'Prosjektledelse',
                'Rensing og luktsanering ved sotskade',]
            ],
            ['paragraph', 'Er man raskt ute og gjør saneringsjobben riktig, kan store verdier spares. Skadebegrensning og sanering ved sotskade handler om sikring av verdier, riving og sanering av berørte flater, vask av bygning og løsøre samt luktsanering.'],
            ['header', 'Vi fokuserer på kostnadseffektive og riktige utbedringer når det gjelder kjemikaliebehandling og fjerning av lukt. Vi har egne programmer og metoder for det meste innen luktsanering og stank fjerning.'],
        ],  
        sidebox: [
            ['Gjennomsiktig samarbeid', 'Når skaden er klar for gjenoppbygging har LAUR Gruppen ansvaret for mengdeberegning, bestilling for riktig type materialer til prosjektet.'],
            ['.', 'Bygningsdelen som er skadet skal da repareres og tilbakeføres tilbake til samme, eller tilnærmet samme stand, slik den var før skaden inntraff.'],
            ['Samarbeidspartnere', 'Vi har egne håndverkere og et nettverk av dyktige elektrikere, rørleggere, malere mm.'],
            ['Kontakt Oss', 'Når uhellet er ute tar du kontakt med oss på telefon 46 91 02 04, eller epost.']
        ],
        link: 'fukt-og-skadesanering',
        ico: fukt_pic,
        tags: [],
        icotext: 'Les mer',
        category: [0,1,0,0]
    },
    {
        brief: 'Bygg og rehabilitering',
        full: [
            ['img', bygg_pic],
            ['header', 'Påbygg, tilbygg og rehabilitering'],
            ['paragraph', 'Vi utvikler og vokser kontinuerlig som et selskap for å tilby bedre løsninger og mer fordelaktige tilbud. Vi bidrar med all vår kompetanse inntil du får det resultatet du ønsker. Vi har gode erfaringer med å optimalisere bokvaliteten i eksisterende boliger – og vi har nært samarbeid med gode rådgivere etter behov.'],
            ['header', 'Laur bygg og rehabilitering utfører det meste innen:'],
            ['header', 'Innendørs:'],
            ['list', 
                ['Endring av planløsning,',
                'Nye overflater',
                'Nytt kjøkken og garderobe',
                'Nye gulv og gulvsliping',
                'Snekkerarbeid',
                'Flislegging',
                'Utskifting av dører og vinduer',
                ],
            ],
            ['header', 'Utendørs: '],
            ['list', 
                ['Utskifting og bytte av vinduer ',
                    'Etablering av takvinduer ',
                    'Tilleggs- /etterisolering',
                    'Skifte kledning ',
                    'Bytte tak',
                    'Maling']
            ],
            ['paragraph', 'Slike prosjekter vi har god erfaring med. Vi kan være en god samarbeidspartner og bidra med verdifulle innspill – og selvsagt utføre vårt håndverk slik at både du og vi kan være stolte av resultatet.'],
            ],  
        sidebox: [
            ['Dyktige arbeidere', 'Opussing og bygging må gi varige resultater i årevis, så vi sørger for å ansette de beste spesialistene som garanterer en god effekt.'],
            ['God kommunikasjon', 'Vi jobber med alle typer prosjekter, vi vet hvor viktig det er å forstå investorens forventninger og behov, og det er derfor vi bryr oss om effektiv kommunikasjon.'],
            ['Attraktive priser', 'Vår prioritet er å inngå rettferdige og fordelaktige kontrakter med våre kunder, hver gang vi leverer høyeste kvalitet til konkurransedyktige priser.'], 
        ],
        link: 'bygg-og-rehabilitering',
        ico: bygg_pic,
        tags: [],
        icotext: 'Les mer',
        category: [1,0,0,0] 
    },
    {
        brief: 'Om Oss',
        full: [
            ['img', about_pic],
            ['header', 'Om Oss'],
            ['header', 'LAUR Gruppen og Skadeteknikk ble etablert av medarbeidere som allerede da hadde over 20 års solid erfaring innen bygg og rehabilitering og skadeteknikk.'],
            ['paragraph', 'Våre oppdrag handler om å levere gode tjenester til våre kunder, ofte i en situasjon når noe uønsket har skjedd.'],
            ['paragraph', 'Kombinasjonen av lang erfaring, faglig dyktighet, egne programmer og metoder og vårt felles ønske om å levere tjenester av høy kvalitet, har gjort oss til en naturlig samarbeidspartner for både offentlige og private kunder'],
            ['paragraph', 'LAUR Gruppen utfører et viktig samfunnsoppdrag. Vi sikrer verdier når det oppstår skader på eiendom. I tillegg hjelper vi kunder innen privat- og næringsmarkedet med oppgraderinger og verdiutvikling.'],
            ['paragraph', 'Vi løser dine problemer raskt, effektivt og med gode resultater. Vi har spisskompetanse i et bredt tjenestespekter som sikrer at vi kan håndtere dine utfordringer med god kvalitet til konkurransedyktige betingelser.'],
            ['paragraph', 'Våre kunder er eiendomsforvaltere, privatpersoner samt industri og forsikringsselskaper som setter høye krav til rask respons og forutsigbare riktige leveranser i alle prosjekter knyttet til skadebegrensning, forebygging og vedlikehold.'],
                      
            
        ],  
        sidebox: [
            ['Høy kvalitet.','LAUR Gruppen er en god samarbeidspartner som man kan stole på.'], 
        ],
        link: 'om-oss',
        ico: about_pic,
        tags: [],
        icotext: 'Les mer',
        category: [0,0,0,0] 
    },
];

export {articles};